import { Routes } from '@angular/router';

import { AuthResolver } from './core/resolvers/auth.resolver.js';

export const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./features/landing/landing.routes'),
		resolve: { auth: AuthResolver },
	},
	{
		path: 'quote',
		loadChildren: () => import('./features/quote/quote.routes'),
		resolve: { auth: AuthResolver },
	},
	{
		path: 'privacy-policy',
		loadChildren: () => import('./features/privacy-policy/privacy-policy.routes'),
		resolve: { auth: AuthResolver },
	},
	{
		path: '**',
		redirectTo: '',
	},
];
