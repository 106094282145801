import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideToastr } from 'ngx-toastr';

import { routes } from './app.routes';
import { GlobalErrorHandler } from './core/error-handler/global-error-handler';
import { graphqlProvider } from './core/grahpql/services';
import { HttpRequestInterceptor } from './core/interceptors/http-request.interceptor';
import { pipesProvider } from './core/pipes/services/pipes.provider';

export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(routes),
		provideHttpClient(withInterceptorsFromDi(), withFetch()),
		provideEnvironmentNgxMask(),
		provideAnimations(),
		provideToastr({
			positionClass: 'toast-top-center',
		}),
		graphqlProvider,
		pipesProvider,
		{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
	],
};
