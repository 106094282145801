import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { StringUtils } from '@pk/powerkioskutils';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

function getRequester(origin: string): string {
	return StringUtils.removeLastCharacter(origin, '/').replace('www.', '').split('/').splice(0, 3).join('/').toLowerCase();
}

const origin = window.location.origin;
environment.apiKey = (environment.apiKeys as any)[getRequester(origin)];

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
