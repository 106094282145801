import { PlatformLocation } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { StringUtils } from '@pk/powerkioskutils';

import { environment } from 'src/environments/environment';
import { ServiceLocator } from './core/auth/services';
import { AppStateService } from './core/auth/services/app-state.service';
import { FooterComponent } from './core/dashboard/components/footer/footer.component';
import { NavbarComponent } from './core/dashboard/components/navbar/navbar.component';

@Component({
	selector: 'pkd-root',
	standalone: true,
	imports: [RouterOutlet, NavbarComponent, FooterComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent {
	public appReady: boolean;

	constructor(
		injector: Injector,
		platformLocation: PlatformLocation,
		private appStateService: AppStateService,
	) {
		ServiceLocator.injector = injector;
		this.appStateService.onReady.subscribe((ready: { app: boolean }) => {
			this.appReady = ready.app;
		});

		if (!this.appStateService.isPlatformBrowser) {
			function getRequester(origin: string): string {
				return StringUtils.removeLastCharacter(origin, '/').replace('www.', '').split('/').splice(0, 3).join('/').toLowerCase();
			}

			const origin = platformLocation.href;
			environment.apiKey = (environment.apiKeys as any)[getRequester(origin)];
		}
	}
}
