import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const clonedRequest = req.clone({
			headers: req.headers.set('Authorization', 'Bearer ' + environment.apiKey),
		});
		return next.handle(clonedRequest).pipe(
			map(event => {
				let unauthenticated = false;

				if (event.type === HttpEventType.Response && event.status === 200 && event.body && Array.isArray(event.body.errors)) {
					const errors = event.body.errors as any[];

					unauthenticated = errors.some(e => e.extensions && e.extensions.code === 'UNAUTHENTICATED');
				}

				if (unauthenticated) {
					// do something
				}

				return event;
			}),
		);
	}
}
