<footer>
	<div class="container-fluid">
		<div class="row d-flex justify-content-center">
			<div class="col-auto">
				<p class="text-center mb-1">
					<a href="index.html">
						<img src="https://static.powerkiosk.com/test/pku-381/assets/img/sunnova-logo-alt.svg" alt="Sunnova" style="height: auto; width: 110px" />
					</a>
				</p>
				<p class="text-center"><a [routerLink]="['privacy-policy']">Privacy Policy</a></p>
			</div>
		</div>
	</div>
</footer>
